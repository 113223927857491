<template>
  <div class="row mb-3">
    <ed-modal v-model="boolExibirCadastroEmail" width="80%">
      <div slot="title">
        {{ formDataEmail.intId ? "Editar email" : "Inserir novo email" }}
      </div>

      <div class="" slot="content">
        <ed-form :formData="formDataEmail" :handlerSave="salvar">
          <ed-input-tipo
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formDataEmail.arrayTipoEmail"
            name="arrayTipoEmail"
            label="Tipo"
            tipo="62"
            required
            multiple
            rules="required"
          />
          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formDataEmail.strEmail"
            name="strEmail"
            label="Email"
            placeholder="email1@teste.com;email2@teste.com"
            info="Se quiser inserir mais de 1 email utilize ';' para seprar os emails. Exemplo: email1@teste.com;email2@teste.com"
            required
            rules="required|email"
          />
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @delete="onDelete"
      :config="{ registerName: 'Inserir novo email' }"
      disabledSearchRows
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputText,
  EdInputTipo,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputText,
    EdInputTipo,
  },
  inject: ["bind"],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroEmail: false,
      arrayHeaders: [
        {
          text: "Tipo",
          sortable: true,
          value: "arrayTipoEmail",
        },
        {
          text: "Email",
          sortable: true,
          collapse: 100,
          value: "strEmail",
        },
      ],
      arrayRows: [],
      formDataEmail: {
        intId: 0,
        tipo_email: null,
        strEmail: null,
      },
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      if (!this.formData.emails) {
        this.formData.emails = [];
      }

      let arrayRows = [];
      this.formData.emails.forEach((objCadastroGeralEmail) => {
        let arrayTipoEmail = [];

        this.bind.arrayTipoEmail.forEach((element) => {
          if (objCadastroGeralEmail.arrayTipoEmail.indexOf(element.intId) != -1) {
            arrayTipoEmail.push(element.strNome);
          }
        });
        let item = {
          intId: objCadastroGeralEmail.intId,
          arrayTipoEmail: arrayTipoEmail.join("<br>"),
          strEmail: objCadastroGeralEmail.strEmail,
          _item: objCadastroGeralEmail,
        };

        arrayRows.push(item);
      });

      this.arrayRows = arrayRows;
      this.$forceUpdate();
    },

    onRegister() {
      this.boolExibirCadastroEmail = !this.boolExibirCadastroEmail;

      this.formDataEmail = {
        intId: 0,
        tipo_email: null,
        strEmail: null,
      };
    },

    onEdit(data) {
      this.formDataEmail = Object.assign(data, {});
      this.boolExibirCadastroEmail = !this.boolExibirCadastroEmail;
    },

    onDelete(data) {
      let self = this;

      data.forEach((intId) => {
        let indexCadastroEmail = self.getIndexByIntId(intId);

        if (indexCadastroEmail != -1) {
          self.formData.emails.splice(indexCadastroEmail, 1);
        }
      });
    },

    getIndexByIntId(intId) {
      return this.formData.emails.findIndex((c) => c.intId == intId);
    },

    salvar() {
      let intIndexCadastroGeralEmail = this.getIndexByIntId(this.formDataEmail.intId);

      if (intIndexCadastroGeralEmail != -1) {
        this.formData.emails[intIndexCadastroGeralEmail] = Object.assign(
          this.formDataEmail,
          {}
        );
      } else {
        this.formData.emails.push({
          intId: (this.formData.emails.length + 1) * -1,
          arrayTipoEmail: this.formDataEmail.arrayTipoEmail,
          tipo_email: this.formDataEmail.tipo_email,
          strEmail: this.formDataEmail.strEmail,
          boolPrincipal: 0,
        });
      }

      this.boolExibirCadastroEmail = !this.boolExibirCadastroEmail;
      this.initialize();
    },
  },
};
</script>
