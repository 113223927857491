<template>
  <div class="row ma-0 pa-0">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Dados cadastrais</span>

      <div class="row">
        <ed-input-tipo
          v-if="bind.boolExibirTipoCadastro"
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.intTipoCadastroGeralId"
          label="Tipo de Cadastro"
          name="intTipoCadastroGeralId"
          rules="required"
          tipo="57"
        />
        <ed-input-select
          v-if="$root.$session.filiais.length > 1"
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.filiais"
          label="Filial"
          name="filiais"
          rules="required"
          :items="$root.$session.filiais"
          multiple
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5"
          v-model="formData.strTipoPessoa"
          name="strTipoPessoa"
          label="Pessoa"
          required
          rules="required"
          :disabled="formData.intTipoCadastroGeralId == 1339"
        />

        <ed-input-text
          v-if="formData.strTipoPessoa == 'PJ'"
          class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7"
          v-model="formData.strCnpj"
          name="strCnpj"
          label="CNPJ"
          required
          v-mask="'99.999.999/9999-99'"
          rules="cnpj"
          @blur="getDadosDocumento"
        />

        <ed-input-text
          v-if="formData.strTipoPessoa == 'PF'"
          class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7"
          v-model="formData.strCnpj"
          name="strCnpj"
          label="CPF"
          v-mask="'999.999.999-99'"
          required
          rules="cpf"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-12 col-lg-812 col-xl-12"
          v-model="formData.strNome"
          name="strNome"
          label="Nome"
          required
          rules="required"
        />

        <ed-input-text
          v-if="formData.strTipoPessoa == 'PJ'"
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strInscricaoEstadual"
          name="strInscricaoEstadual"
          label="Inscrição Estadual"
        />

        <ed-input-text
          v-if="formData.strTipoPessoa == 'PF'"
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formData.strRg"
          name="strRg"
          label="RG"
        />

        <ed-input-text
          v-if="formData.strTipoPessoa == 'PF'"
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formData.strNit"
          name="strNit"
          label="NIT"
          rules="pis"
          v-mask="'999.99999.99-9'"
        />

        <ed-input-text
          v-if="formData.strTipoPessoa == 'PF'"
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formData.strCei"
          name="strCei"
          rules="cei"
          v-mask="'99.999.99999/99'"
          label="CEI"
        />

        <ed-input-auto-complete
          v-if="formData.strTipoPessoa == 'PJ'"
          class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
          v-model="formData.strCnae"
          label="Cnae"
          name="strCnae"
          route="Common/Cnae"
          itemValue="strCodigo"
          @selected="setCnae"
        />

        <ed-input-text
          v-if="formData.strTipoPessoa == 'PJ'"
          type="number"
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formData.intGrauRisco"
          name="intGrauRisco"
          label="Grau de Risco"
        />
      </div>

      <span class="form-title">Contato</span>

      <div class="row">
        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.strTelefone"
          name="strTelefone"
          label="Telefone"
          v-mask="'(99) 9999-9999'"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.strCelular"
          name="strCelular"
          label="Celular"
          v-mask="'(99) 99999-9999'"
        />

        <ed-input-text-area
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strObservacao"
          name="strObservacao"
          label="Observações"
        />
      </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Endereço</span>
      <ed-endereco :formData="formData" />

      <template v-if="bind.intTipoCadastroGeralId == 58">
        <span class="form-title"
          >Esocial</span
        >
        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0"
          v-model="formData.strGrupoEsocial"
          label="Grupo"
          name="strGrupoEsocial"
          :items="bind.arrayGrupoEsocial"
        />
      </template>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <campos-personalizado
        :formData="formData"
        :key="'campo-' + $root.$session.versao"
        :intTipoModeloId="getTipoModelo()"
        :parametros="{}"
        @salvar="(x) => (formData.arrayCamposPersonalizado = x)"
      />
    </div>
    <div
      class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left"
      v-if="objDadosDocumento"
    >
      <ed-alert type="warning">
        <p><b>Informações da Empresa na Receita Federal</b></p>
        <span class="ed-full-width">
          Data de Abertura: {{ objDadosDocumento.strDataAbertura }}
        </span>

        <span class="ed-full-width">
          Data Última Atualização:
          {{ objDadosDocumento.strDataUltimaAtualizacao }}
        </span>

        <span class="ed-full-width">
          Situação: {{ objDadosDocumento.boolStatus ? "Ativo" : "Inativo" }}
        </span>
      </ed-alert>
    </div>
  </div>
</template>

<script>
import EdEndereco from "@/components/cadastro/geral/partials/endereco";
import CamposPersonalizado from "@/components/cadastro/formularioPersonalizado/formatar";
import {
  EdAlert,
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputTipo,
  EdInputSwitch,
  EdInputTime,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdAlert,
    EdInputText,
    EdInputSelect,
    EdInputTipo,
    EdInputTextArea,
    EdInputTime,
    EdInputSwitch,
    EdInputAutoComplete,
    EdEndereco,
    CamposPersonalizado,
  },
  inject: ["getTipoModelo", "bind"],
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {
      arrayCnpjConsultado: [],
      objDadosDocumento: null,
    };
  },
  watch: {},
  methods: {
    setCnae(item) {
      if (item?.strCodigo) {
        this.formData.intGrauRisco = item.intGrauRisco;
      }
    },

    getDadosDocumento() {
      if (
        !this.formData.intId &&
        this.formData.strCnpj &&
        this.$utilidade.isCnpj(this.formData.strCnpj) &&
        this.arrayCnpjConsultado.indexOf(this.formData.strCnpj) == -1
      ) {
        this.$root.$dialog.loading(true);

        this.$root.$request
          .get("Cadastro/CadastroGeral", {
            boolConsultarDocumento: true,
            strCnpj: this.formData.strCnpj,
          })
          .then((objResponse) => {
            this.$root.$dialog.loading(false);

            if (objResponse.status == 200 && objResponse.result) {
              this.objDadosDocumento = objResponse.result;

              this.arrayCnpjConsultado.push(this.formData.strCnpj);

              this.formData.strNome = objResponse.result.strNome;
              this.formData.strCnae = objResponse.result.strCnae;
              this.formData.intGrauRisco = objResponse.result.intGrauRisco;
              this.formData.strTelefone = objResponse.result.strTelefone;
              this.formData.endereco = Object.assign(
                objResponse.result.endereco,
                {}
              );

              if (!this.formData.setores || !this.formData.setores.length) {
                this.formData.setores = Object.assign(
                  [
                    {
                      intId: 0,
                      intGhe: 1,
                      strNome: "ADMINISTRATIVO",
                      boolAtivo: true,
                    },
                  ],
                  {}
                );
              }

              if (!!this.formData.unidades || this.formData.unidades.length) {
                this.formData.unidades = Object.assign(
                  [
                    {
                      intId: 0,
                      intUnidade: 1,
                      strNome: "MATRIZ",
                      strCnpj: this.$utilidade.onlyNumber(
                        this.formData.strCnpj
                      ),
                      intTipoDocumentoId: 1,
                      intTipoEstabelecimentoId: 1,
                      boolAtivo: true,
                    },
                  ],
                  {}
                );
              }

              if (objResponse.result.emails.length) {
                if (!this.formData.emails || !this.formData.emails.length) {
                  this.formData.emails = Object.assign(
                    objResponse.result.emails,
                    {}
                  );
                }
              }

              this.$forceUpdate();
            }
          });
      }
    },
  },
};
</script>
