<template>
  <div class="row mb-3">
    <ed-modal v-model="boolExibirCadastroSetor" width="80%">
      <div slot="title">
        {{ formDataSetor.intId ? "Editar setor" : "Inserir novo setor" }}
      </div>

      <div class="" slot="content">
        <ed-form :formData="formDataSetor" :handlerSave="salvar" disableFixedSave>
          <div class="row">
            <!-- <ed-input-text
            class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"
            v-model="formDataSetor.intGhe"
            name="intGhe"
            label="Código"
            required
            type="number"
            rules="required"
          /> -->
            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataSetor.strNome"
              name="strNome"
              label="Nome"
              required
              rules="required"
            />
            <ed-input-text-area
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataSetor.strDescricaoAtividades"
              name="strDescricaoAtividades"
              label="Descrição do setor"
              placeholder="Exemplo: Sala de 70m² com piso cerâmico, paredes em alvenaria, ventilação e iluminação artificiais"
            />

            <ed-input-switch
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataSetor.boolAtivo"
              name="boolAtivo"
              label="Setor ativo?"
              required
              rules="required"
            />
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @delete="onDelete"
      :config="{ registerName: 'Inserir novo setor' }"
      disabledSearchRows
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputText,
  EdInputTextArea,
  EdInputSwitch,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputText,
    EdInputSwitch,
    EdInputTextArea,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroSetor: false,
      arrayHeaders: [
        {
          text: "Setor",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Descrição do setor",
          sortable: true,
          collapse: 70,
          value: "strDescricaoAtividades",
        },
      ],
      arrayRows: [],
      formDataSetor: {
        intId: 0,
        intGhe: 1,
        strNome: null,
        strDescricaoAtividades: null,
        boolAtivo: 1,
      },
      intRegistroInserido: 0,
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      if (!this.formData.setores) {
        this.formData.setores = [];
      }

      let arrayRows = [];
      this.formData.setores.forEach((objCadastroGeralEmail) => {
        let item = {
          intId: objCadastroGeralEmail.intId,
          intGhe: objCadastroGeralEmail.intGhe,
          strNome: objCadastroGeralEmail.strNome,
          strDescricaoAtividades: objCadastroGeralEmail.strDescricaoAtividades,
          boolAtivo: objCadastroGeralEmail.boolAtivo,
          _item: objCadastroGeralEmail,
        };

        arrayRows.push(item);
      });

      this.arrayRows = arrayRows;
    },

    onRegister() {
      this.boolExibirCadastroSetor = !this.boolExibirCadastroSetor;

      let objUltimoSetor = this.getUltimoRegistro();

      this.formDataSetor = {
        intId: 0,
        intGhe: objUltimoSetor ? objUltimoSetor.intGhe + 1 : 1,
        strNome: null,
        strDescricaoAtividades: null,
        boolAtivo: 1,
      };
    },

    onEdit(data) {
      this.formDataSetor = Object.assign(data, {});
      this.boolExibirCadastroSetor = !this.boolExibirCadastroSetor;
    },

    onDelete(data) {
      let self = this;
      data.forEach((intId) => {
        let indexCadastroEmail = self.getIndexByIntId(intId);

        if (indexCadastroEmail != -1) {
          self.formData.setores.splice(indexCadastroEmail, 1);
        }
      });
    },

    getIndexByIntId(intId) {
      return this.formData.setores.findIndex((c) => c.intId == intId);
    },

    getUltimoRegistro() {
      let objUltimoSetor = null;

      if (this.formData.setores.length) {
        objUltimoSetor = this.formData.setores[this.formData.setores.length - 1];
      }

      return objUltimoSetor;
    },

    salvar() {
      let intIndexCadastroGeralEmail = this.getIndexByIntId(this.formDataSetor.intId);

      if (intIndexCadastroGeralEmail != -1) {
        this.formData.setores[intIndexCadastroGeralEmail] = Object.assign(
          this.formDataSetor,
          {}
        );
      } else {
        let objUltimoSetor = this.getUltimoRegistro();

        this.intRegistroInserido--;

        this.formData.setores.push({
          intId: this.intRegistroInserido,
          intGhe: objUltimoSetor ? objUltimoSetor.intGhe + 1 : 1,
          strNome: this.formDataSetor.strNome,
          strDescricaoAtividades: this.formDataSetor.strDescricaoAtividades,
          boolAtivo: this.formDataSetor.boolAtivo,
        });
      }

      this.boolExibirCadastroSetor = !this.boolExibirCadastroSetor;
    },
  },
};
</script>
