<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Cadastro Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-funcionario" v-if="formData.intTipoCadastroGeralId == 58">
        Funcionários
        <i :class="$utilidade.getIcone('funcionario') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-cobranca" v-if="formData.intTipoCadastroGeralId == 58">
        Cobrança
        <i :class="$utilidade.getIcone('cobranca') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-email">
        Email
        <i :class="$utilidade.getIcone('email') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-setores" v-if="formData.intTipoCadastroGeralId == 58">
        Setores
        <i :class="$utilidade.getIcone('setores') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-unidade" v-if="formData.intTipoCadastroGeralId == 58">
        Unidade de Trabalho
        <i :class="$utilidade.getIcone('frenteTrabalho') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent"
    >
      <cadastro-geral :formData="formData" v-if="strTab == 'tab-cadastro'" />
      <cadastro-email :formData="formData" v-if="strTab == 'tab-email'" />
      <cadastro-setor :formData="formData" v-if="strTab == 'tab-setores'" />
      <cadastro-unidade :formData="formData" v-if="strTab == 'tab-unidade'" />
      <cadastro-cobranca :formData="formData" v-if="strTab == 'tab-cobranca'" />
      <cadastro-funcionario
        :empresa="formData"
        v-if="strTab == 'tab-funcionario' && formData.intId"
        boolEmpresa
      />
    </ed-form>
  </div>
</template>

<script>
import CadastroGeral from "./partials/geral";
import CadastroEmail from "./partials/email";
import CadastroSetor from "./partials/setor";
import CadastroUnidade from "./partials/unidade";
import CadastroCobranca from "./partials/cobranca.vue";
import CadastroFuncionario from "@/components/cadastro/funcionario/partials/vinculo.vue";

import { EdForm } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intCadastroGeralId: {
      type: [Number, String],
      required: true,
      default: null,
    },
    intTipoCadastroGeralId: {
      type: [Number, String],
      required: true,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    CadastroEmail,
    CadastroSetor,
    CadastroUnidade,
    CadastroCobranca,
    CadastroFuncionario,
  },
  provide() {
    return {
      getTipoModelo: this.getTipoModelo,
      bind: this.bind,
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      strTab: "tab-cadastro",
      bind: {
        boolExibirTipoCadastro: !this.intTipoCadastroGeralId,
        intTipoCadastroGeralId: this.intTipoCadastroGeralId,
        arrayTipoEmail: [],
        arrayGrupoEsocial: [
          { intId: 1, strNome: "GRUPO 1" },
          { intId: 2, strNome: "GRUPO 2" },
          { intId: 3, strNome: "GRUPO 3" },
          { intId: 4, strNome: "GRUPO 4" },
        ],
      },
      formData: {
        intId: 0,
        intTipoCadastroGeralId: this.intTipoCadastroGeralId,
        strTipoPessoa: "PJ",
        emails: [],
        setores: [],
        unidades: [],
        filiais: [
          this.$root.$session.filial ? this.$root.$session.filial.id : 0,
        ],
        cadastros: [{ intTipoCadastroGeralId: this.intTipoCadastroGeralId }],
        endereco: {
          intId: 0,
          strGrupoEsocial: 2,
          strCidade: null,
          intCep: null,
          strEstado: null,
          strLogradouro: null,
          strNumero: null,
          strComplemento: null,
          strBairro: null,
        },
      },
    };
  },
  watch: {
    intCadastroGeralId() {
      this.initialize();
    },
  },
  methods: {
    getTipoModelo() {
      switch (this.intTipoCadastroGeralId) {
        //EMPRESA
        case 58:
          return 308;
          break;

        //Parceiro
        case 59:
          return 314;
          break;

        //Credenciada
        case 60:
          return 0;
          break;

        //Fornecedor

        case 300:
          return 315;
          break;
      }
    },

    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.$root.$request.get("Cadastro/CadastroGeral", {
          boolInicializar: true,
          intCadastroGeralId: this.intCadastroGeralId,
        }),
      ]).then(([objDados]) => {
        this.$root.$dialog.loading(false);

        if (objDados.status == 200) {
          this.bind.arrayTipoEmail = objDados.result.arrayTipoEmail;

          if (objDados.result.objCadastroGeral) {

            if(this.intTipoCadastroGeralId){
              objDados.result.objCadastroGeral.intTipoCadastroGeralId = this.intTipoCadastroGeralId
            }
            this.formatarCadastroGeral(objDados.result.objCadastroGeral);
          }
        }
      });
    },

    formatarCadastroGeral(objCadastroGeral) {
      let arrayFilial = objCadastroGeral.filiais;

      objCadastroGeral.filiais = [];

      arrayFilial.forEach((element) => {
        objCadastroGeral.filiais.push(element.intFilialId);
      });

      if (!objCadastroGeral.endereco) {
        objCadastroGeral.endereco = {
          intId: 0,
          strCidade: null,
          intCep: null,
          strEstado: null,
          strLogradouro: null,
          strNumero: null,
          strComplemento: null,
          strBairro: null,
        };
      }

      this.formData = _.cloneDeep(objCadastroGeral);
    },

    salvar() {

      if(this.intTipoCadastroGeralId){
        this.formData.intTipoCadastroGeralId = this.intTipoCadastroGeralId
      }

      // if (this.formData.intTipoCadastroGeralId == 58 && !this.formData.setores.length) {
      //   this.$root.$dialog.warning("É necessário inserir pelo menos 1 setor");
      //   this.strTab = "tab-setores";
      //   return;
      // }

      // if (this.formData.intTipoCadastroGeralId == 58 && !this.formData.unidades.length) {
      //   this.$root.$dialog.warning(
      //     "É necessário inserir pelo menos 1 Unidade de Trabalho"
      //   );
      //   this.strTab = "tab-unidade";
      //   return;
      // }

      if(this.formData.cadastros.length){
        this.formData.cadastros[0].intTipoCadastroGeralId = this.formData.intTipoCadastroGeralId 
      }

      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("Cadastro/CadastroGeral", this.formData)
        .then((objResponse) => {

          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            let strRota = this.$route.name;

            if (strRota.indexOf(".register") != -1) {
              if (this.intTipoCadastroGeralId) {
                this.$router.push({
                  name: strRota.replace(".register", ".edit"),
                  params: { intId: objResponse.result.intId,intTipoCadastroGeralId:this.intTipoCadastroGeralId },
                });
              }
              this.$emit('salvar',objResponse.result)
            } else {
              this.formatarCadastroGeral(objResponse.result);
            }
          }
        });
    },
  },
};
</script>
