<template>
  <div class="row ma-0 pa-0">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Dados cadastrais</span>

      <div class="row">
        <ed-input-tipo
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.intTipoCadastroGeralId"
          label="Tipo de Cadastro"
          name="intTipoCadastroGeralId"
          rules="required"
          tipo="57"
        />
      </div>
    </div>

  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputText,
  EdInputTipo,
  EdInputTextArea,
  EdInputSwitch,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputText,
    EdInputTipo,
    EdInputSwitch,
    EdInputTextArea,
  },
  provide() {
    return {};
  },
  mounted() {

  },
  created() {},
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>
