<template>
  <div class="row mb-3">
    <ed-modal v-model="boolExibirCadastroUnidade" width="90%">
      <div slot="title">
        {{ formDataUnidade.intId ? "Editar setor" : "Inserir novo setor" }}
      </div>

      <div class="" slot="content">
        <ed-form :formData="formDataUnidade" :handlerSave="salvar" disableFixedSave>
          <div class="row">
            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"
              v-model="formDataUnidade.intUnidade"
              name="intUnidade"
              label="Código"
              required
              type="number"
              rules="required"
            />

            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10"
              v-model="formDataUnidade.strNome"
              name="strNome"
              label="Nome"
              required
              rules="required"
            />

            <ed-input-tipo
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-model="formDataUnidade.intTipoDocumentoId"
              name="intTipoDocumentoId"
              label="Tipo de documento"
              tipo="70"
            />

            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-model="formDataUnidade.strCnpj"
              name="strCnpj"
              label="Nº do Documento"
              :required="formDataUnidade.intTipoDocumentoId ? true : false"
              :rules="formDataUnidade.intTipoDocumentoId ? 'required' : ''"
              type="number"
            />

            <ed-input-tipo
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-model="formDataUnidade.intTipoEstabelecimentoId"
              name="intTipoEstabelecimentoId"
              label="Localização do ambiente"
              tipo="67"
            />

            <ed-input-text-area
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataUnidade.strDescricaoAmbienteTrabalho"
              name="strDescricaoAmbienteTrabalho"
              label="Descrição do setor"
            />

            <ed-input-switch
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataUnidade.boolAtivo"
              name="boolAtivo"
              label="Setor ativo?"
              required
              rules="required"
            />
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @delete="onDelete"
      :config="{ registerName: 'Inserir novo setor' }"
      disabledSearchRows
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputText,
  EdInputTipo,
  EdInputTextArea,
  EdInputSwitch,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputText,
    EdInputTipo,
    EdInputSwitch,
    EdInputTextArea,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroUnidade: false,
      arrayHeaders: [
        {
          text: "Código",
          sortable: true,
          value: "intUnidade",
        },
        {
          text: "Unidade",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Documento",
          sortable: true,
          value: "strCnpj",
        },
        {
          text: "Atividades do ambiente de trabalho",
          sortable: true,
          collapse: 70,
          value: "strDescricaoAmbienteTrabalho",
        },
      ],
      arrayRows: [],
      formDataUnidade: {
        intId: 0,
        intUnidade: 1,
        strNome: null,
        strDescricaoAmbienteTrabalho: null,
        boolAtivo: 1,
      },
      intRegistroInserido: 0,
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      if (!this.formData.unidades) {
        this.formData.unidades = [];
      }

      let arrayRows = [];
      this.formData.unidades.forEach((objCadastroGeralUnidade) => {
        let item = {
          intId: objCadastroGeralUnidade.intId,
          intUnidade: objCadastroGeralUnidade.intUnidade,
          strNome: objCadastroGeralUnidade.strNome,
          strCnpj: objCadastroGeralUnidade.strCnpj,
          strDescricaoAmbienteTrabalho:
            objCadastroGeralUnidade.strDescricaoAmbienteTrabalho,
          boolAtivo: objCadastroGeralUnidade.boolAtivo,
          _item: objCadastroGeralUnidade,
        };

        arrayRows.push(item);
      });

      this.arrayRows = arrayRows;
    },

    onRegister() {
      this.boolExibirCadastroUnidade = !this.boolExibirCadastroUnidade;

      let objUltimoSetor = this.getUltimoRegistro();
      let strNome = null;

      if (!this.formData.unidades.length) {
        strNome = this.formData.strNome;
      }

      this.formDataUnidade = {
        intId: 0,
        intUnidade: objUltimoSetor ? objUltimoSetor.intUnidade + 1 : 1,
        strNome: strNome,
        strDescricaoAmbienteTrabalho: null,
        boolAtivo: 1,
      };
    },

    onEdit(data) {
      console.log("edit unidade", data);
      this.formDataUnidade = _.cloneDeep(data);
      this.boolExibirCadastroUnidade = !this.boolExibirCadastroUnidade;
    },

    onDelete(data) {
      let self = this;

      data.forEach((intId) => {
        let indexCadastroEmail = self.getIndexByIntId(intId);

        if (indexCadastroEmail != -1) {
          self.formData.unidades.splice(indexCadastroEmail, 1);
        }
      });
    },

    getIndexByIntId(intId) {
      return this.formData.unidades.findIndex((c) => c.intId == intId);
    },

    getUltimoRegistro() {
      let objUltimoSetor = null;

      if (this.formData.unidades.length) {
        objUltimoSetor = this.formData.unidades[this.formData.unidades.length - 1];
      }

      return objUltimoSetor;
    },

    salvar() {
      let intIndexCadastroGeralEmail = this.getIndexByIntId(this.formDataUnidade.intId);

      if (intIndexCadastroGeralEmail != -1) {
        this.formData.unidades[intIndexCadastroGeralEmail] = Object.assign(
          this.formDataUnidade,
          {}
        );
      } else {
        let objUltimoSetor = this.getUltimoRegistro();

        this.intRegistroInserido--;

        this.formData.unidades.push({
          intId: this.intRegistroInserido,
          intUnidade: objUltimoSetor ? objUltimoSetor.intUnidade + 1 : 1,
          strNome: this.formDataUnidade.strNome,
          strDescricaoAmbienteTrabalho: this.formDataUnidade.strDescricaoAmbienteTrabalho,
          boolAtivo: this.formDataUnidade.boolAtivo,
        });
      }

      this.boolExibirCadastroUnidade = !this.boolExibirCadastroUnidade;
    },
  },
};
</script>
